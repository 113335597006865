import { graphql } from 'gatsby';
import { CmsContext } from 'logic/context/cms';
import React from 'react';
import { Query } from 'src/../graphql-types';
import { App } from '../app/app';
import { findContentfulFaqMetaByPath, toFaqPageSpec } from 'logic/faq-meta/faq-new';
import { AppRoute } from 'app/app.route.const';

interface PageNotFoundProps {
  data: Query;
}

const PageNotFound: React.FC<PageNotFoundProps> = ({
  data: { allContentfulFaqMeta, allContentfulCityContent, contentfulOtherPages },
}) => {
  const faqMetas = allContentfulFaqMeta.edges.map(({ node }) => {
    return { ...node };
  });
  const cityContents = allContentfulCityContent?.edges.map(({ node }) => node);

  const onlineTutoringFAQMeta = findContentfulFaqMetaByPath(faqMetas, AppRoute.OnlineTutoring);
  const onlineTutoringFAQ = toFaqPageSpec(onlineTutoringFAQMeta?.faq ?? []);

  return (
    <CmsContext.Provider value={{ faq: faqMetas, cityContents, page_meta: contentfulOtherPages, onlineTutoringFAQ }}>
      <App />
    </CmsContext.Provider>
  );
};

export default PageNotFound;

export const query = graphql`
  {
    allContentfulFaqMeta(filter: { node_locale: { eq: "de" } }) {
      edges {
        node {
          path
          faq {
            ... on ContentfulFaqMetaQuestion {
              question
              answer {
                answer
              }
            }
          }
        }
      }
    }
    allContentfulCityContent(filter: { node_locale: { eq: "de" } }) {
      edges {
        node {
          path
          leftColumn {
            leftColumn
          }
          rightColumn {
            rightColumn
          }
        }
      }
    }
    contentfulOtherPages {
      impressumPageMeta {
        metatitle
        metadescription
      }
      datapolicyPageMeta {
        metatitle
        metadescription
      }
      legalPageMeta {
        metatitle
        metadescription
      }
    }
  }
`;
